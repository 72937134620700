<template>
<!--//app-header-->
<div class="app-wrapper">

    <div class="app-content pt-3 p-md-3 p-lg-4">
        <div class="container-fluid">
            <div class="container-fluid mt-5">
                <div class="row">
                    <div class="col-md-12">
                        <div class="app-card app-card-orders-table shadow-sm mb-5">
                            <div class="app-card-body">

                              

                                <div class="row g-3 mb-4 ms-2 me-2 align-items-center justify-content-between">
                                    <div class="col-12">
                                        <h1 class="app-page-title mb-0">Data Barang</h1>
                                    </div>
                                    
                                    <div class="col-auto">
                                        <div class="page-utilities">
                                            <div class="row g-2 justify-content-start justify-content-md-end align-items-center">
                                                <div class="col-auto">
                                                    <div class="docs-search-form row gx-1 align-items-center">
                                                       
                                                        <div class="col-8 col-md-auto mt-2 mt-md-0">

                                                            <div class="input-group ">
                                                                <input v-on:keyup.enter="getSearch" v-model="search" type="text" id="search-docs" name="searchdocs" class="form-control search-docs" placeholder="Search">
                                                                <button @click="getReset" class="btn btn-outline-secondary" type="button" id="button-addon2" style="border: 1px solid #e7e9ed"> X </button>
                                                            </div>
                                                        </div>
                                                        <div class="col-4 col-md-auto mt-2 mt-md-0">
                                                            <button @click="getSearch" type="submit" class="btn app-btn-secondary w-100" style="height:40px">Search</button>
                                                        </div>
                                                    </div>

                                                </div>
                                                <!--//col-->

                                                <div class="col-auto">
                                                   
                                                </div>
                                            </div>
                                            <!--//row-->
                                        </div>
                                        <!--//table-utilities-->
                                    </div>
                                    <!--//col-auto-->
                                    <div v-if="tambahdeleteSuccess" class="alert alert-success" role="alert">
                                        Data Berhasil Dihapus
                                    </div>
                                    <div v-if="tambahdeleteError" class="alert alert-danger" role="alert">
                                        Data Gagal Dihapus, data barang sudah memiliki transaksi
                                    </div>
                                </div>
                                <!--//row-->

                                <div class="table-responsive m-2">
                                    <table class="table app-table-hover mb-0 text-center">
                                        <thead>
                                            <tr>
                                                <th class="cell">No</th>
                                                <th class="cell">Foto</th>
                                                <th class="cell">Nama Barang</th>
                                                <th class="cell">Kantor Cabang</th>
                                                <th class="cell">Kode Barcode</th>
                                                <th class="cell">Merek</th>
                                                <th class="cell">Harga</th>
                                                <th class="cell">Stok</th>
                                                <th class="cell">Penyimpanan</th>
                                                <th class="cell">Aksi</th>
                                            </tr>
                                        </thead>

                                        <tbody style="border-top: 1px solid grey" v-if="loading === false">

                                            <tr v-for="(post, index) in posts" :key="index">
                                                <td class="cell">{{ index +1 }}</td>
                                                <td class="cell"><img v-bind:src="link+'storage/gambar/' + post.gambar" style="width: 50px; height: 50px" /> </td>
                                                <td class="cell">{{ post.nama_barang }}</td>
                                                <td class="cell">{{ post.kantor_cabang.nama_cabang }}</td>
                                                <td class="cell">
                                                    <vue-barcode :value=" post.kode_barcode " :option="{format:'code128'}" style="width:120px" tag="img"></vue-barcode>

                                                </td>
                                                <td class="cell">{{ post.merek}}</td>
                                                <td class="cell">
                                                    <s style="color:red" v-if="post.diskon!=0">{{ "Rp" + post.harga_cash.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")  + ',00'}}</s>
                                                    {{ "Rp" + (post.harga_cash-(post.harga_cash*post.diskon/100)).toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")  + ',00'}}
                                                    <br>
                                                    <s style="color:red" v-if="post.diskon!=0">{{ "Rp" + post.harga_hutang.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")  + ',00'}}</s>
                                                    {{ "Rp" + (post.harga_hutang-(post.harga_hutang*post.diskon/100)).toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")  + ',00'}}
                                                </td>
                                                <td class="cell">{{ post.stok }}</td>
                                                <td class="cell">Rak ke- {{ post.rak }}</td>
                                                <td class="text-center cell">
                                                    <!-- Button trigger modal -->
                                                    <button type="button" class="btn btn-primary text-white btn-sm me-2" data-bs-toggle="modal" :data-bs-target="'#staticSee'+index">
                                                        Detail
                                                    </button>

                                                    <!-- Modal -->
                                                    <div class="modal fade" :id="'staticSee' + index" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                        <div class="modal-dialog modal-lg">
                                                            <div class="modal-content">
                                                                <div class="modal-header">
                                                                    <h5 class="modal-title" id="staticBackdropLabel">Detail</h5>
                                                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                                </div>
                                                                <div class="modal-body">
                                                                    <div class="table-responsive m-2">
                                                                        <table class="table app-table-hover mb-0 text-center">
                                                                            <tbody style="border-top: 1px solid grey" v-if="loading === false">
                                                                                <tr>
                                                                                    <td class="cell">Nama Barang</td>
                                                                                    <td class="cell">:</td>
                                                                                    <td class="cell">{{post.nama_barang}}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td class="cell">Cabang</td>
                                                                                    <td class="cell">:</td>
                                                                                    <td class="cell">{{post.kantor_cabang.nama_cabang}}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td class="cell">Kode Barcode</td>
                                                                                    <td class="cell">:</td>
                                                                                    <td class="cell">
                                                                                        <vue-barcode :value=" post.kode_barcode " style="width:120px" tag="img"></vue-barcode>

                                                                                        
                                                                                        </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td class="cell">Kategori</td>
                                                                                    <td class="cell">:</td>
                                                                                    <td class="cell"><a v-if="post.kategori">{{post.kategori.nama_kategori}}</a></td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td class="cell">Diskon</td>
                                                                                    <td class="cell">:</td>
                                                                                    <td class="cell">{{ post.diskon}}%</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td class="cell">Harga Cash</td>
                                                                                    <td class="cell">:</td>
                                                                                    <td class="cell"> <s style="color:red" v-if="post.diskon!=0">{{ "Rp" + post.harga_cash.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")  + ',00'}}</s>
                                                                                        {{ "Rp" + (post.harga_cash-(post.harga_cash*post.diskon/100)).toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")  + ',00'}}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td class="cell">Harga Hutang</td>
                                                                                    <td class="cell">:</td>
                                                                                    <td class="cell"> <s style="color:red" v-if="post.diskon!=0">{{ "Rp" + post.harga_hutang.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")  + ',00'}}</s>
                                                                                        {{ "Rp" + (post.harga_hutang-(post.harga_hutang*post.diskon/100)).toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")  + ',00'}}
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td class="cell">Stok</td>
                                                                                    <td class="cell">:</td>
                                                                                    <td class="cell">{{ post.stok}}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td class="cell">Deskripsi</td>
                                                                                    <td class="cell">:</td>
                                                                                    <td class="cell">{{ post.deskripsi}}</td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td class="cell">Tempat Penyimpanan</td>
                                                                                    <td class="cell">:</td>
                                                                                    <td class="cell">Rak ke- {{ post.rak}}</td>
                                                                                </tr>

                                                                            </tbody>
                                                                        </table>
                                                                        <!-- <div class="text-center mt-3" v-if="post.tipe_harga.length>0">
                                                                            <button class="btn btn-sm btn-primary text-white" @click="showdetailtipes"><a v-if="showdetailtipe">Tutup</a> <a v-else>Tampilkan</a> Tipe Harga</button>
                                                                        </div>
                                                                        <table class="table app-table-hover mb-0  mt-2" v-if="showdetailtipe">
                                                                            <thead>
                                                                                <tr>

                                                                                    <th class="cell">Nama Tipe</th>
                                                                                    <th class="cell">Min Jumlah</th>
                                                                                    <th class="cell">Diskon</th>
                                                                                    <th class="cell">Harga</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody style="" v-if="loading === false">
                                                                                <tr v-for="(post2, index) in post.tipe_harga" :key="index">

                                                                                    <td class="cell text-center">{{post2.nama_tipe}}</td>
                                                                                    <td class="cell text-center">{{post2.min_jumlah}}</td>
                                                                                    <td class="cell text-center">{{post2.harga}}%</td>
                                                                                    <td class="cell text-left">Harga Cash : {{ "Rp " +(post.harga_cash- (post.harga_cash * (post2.harga+post.diskon)/100)).toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")  + ',00'}} <br>
                                                                                        Harga Hutang : {{ "Rp " + (post.harga_hutang-(post.harga_hutang * (post2.harga+post.diskon)/100)).toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1\.")  + ',00'}}
                                                                                    </td>
                                                                                </tr>

                                                                            </tbody>
                                                                        </table> -->
                                                                    </div>

                                                                </div>
                                                                <div class="modal-footer">
                                                                    <button type="button" class="btn btn-primary text-white" data-bs-dismiss="modal">Oke</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                        

                                              
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tbody style="border-top: 1px solid grey" v-else>
                                            <tr>
                                                <td colspan="9" class="text-center " style="height: 200px; padding-top: 100px;">
                                                    <h3> Loading....</h3>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <!-- <Pagination :data="laravelData" @pagination-change-page="getResults" /> -->

                                    <nav class="app-pagination mt-3">
                                        <ul class="pagination justify-content-center">

                                            <li :class="[pagination.prev_page_url == null ?  'disabled page-item'  : 'page-item'] "><a class="page-link" @click="onClicks(pagination.prev_page_url)">Previous</a></li>
                                            <li v-if="pagination.current_page !=1 && pagination.current_page !=2" :class="[pagination.current_page == 1 ?  ' page-item'  : 'page-item'] "><a class="page-link" @click="onClick(1)">1</a></li>
                                            <li :class="[pagination.current_page == item ?  'active page-item'  : 'page-item'] " v-for="item =1 in pagination.last_page" :key="item">
                                                <a class="page-link" v-if="Math.abs(item -1 - pagination.current_page) < 3 || item -1 == pagination.total - 1 " href="#" @click="onClick(item)" :class="{current: pagination.current_page === item-1, last: (item -1== pagination.total - 1 && Math.abs(item -1- pagination.current_page) > 3), first:(item -1== 0 && Math.abs(item -1- pagination.current_page) > 3)}">{{ item+1-1 }}</a>
                                            </li>
                                             <li v-if="pagination.current_page !=pagination.last_page && pagination.current_page <pagination.last_page-5 " :class="[pagination.current_page == pagination.last_page ?  ' page-item'  : 'page-item'] "><a class="page-link" @click="onClick(pagination.last_page)">{{pagination.last_page}}</a></li>
                                            <!-- <li v-if="pagination.current_page !=pagination.last_page && pagination.current_page <pagination.last_page-3 " :class="[pagination.current_page == pagination.last_page ?  ' page-item'  : 'page-item'] "><a class="page-link" @click="onClick(pagination.last_page)">{{pagination.last_page}}</a></li> -->
                                            <!-- <div v-for="item =1 in pagination.last_page" :key="item">
                                                <li :class="[pagination.current_page == item ?  'active page-item'  : 'page-item'] "><a class="page-link" @click="onClick(item)">{{item}}</a></li>
                                            </div> -->
                                            <li class="page-item" :class="[pagination.next_page_url == null ?  'disabled page-item'  : 'page-item'] "><a class="page-link" @click="onClicks(pagination.next_page_url)">Next</a></li>
                                        </ul>
                                    </nav>

                                    <!--//app-pagination-->

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios'

// import { onMounted, ref } from 'vue'

export default {

    computed: {
        totalPages: function () {
            return Math.ceil(this.pagination.total / this.pagination.per_page)
        }
    },
    data() {
        return {
            // Our data object that holds the Laravel paginator data
            posts: {},
            page: 1,
            pagination: {
                "total": 0,
                "per_page": 8,
                "current_page": 1,
                "last_page": 1,
                "next_page_url": "...",
                "prev_page_url": "...",
                "from": 1,
                "to": 8,
            },
            stokstokmasuk: '',
            barcodestokmasuk: '',
            hargacashstokmasuk: '',
            hargahutangstokmasuk: '',
            importMessage: [],
            kantorcabangidImport: '',
            link: this.globalApiUrl,
            loading: false,
            loggedIn: localStorage.getItem('loggedIn'),
            token: localStorage.getItem('token'),
            search: "",
            searchCabang: localStorage.getItem('cabang'),
            importfile: undefined,
            // tambah : [],

            kantorcabangid: '',
            kategoriid: '',
            supplierid: '',
            nama: '',
            deskripsi: '',
            barcode: '',
            hargacash: '',
            hargahutang: '',
            stok: '',
            diskon: 0,
            merek: '',
            satuan: '',
            jenis: '',
            rak: '',
            file: undefined,

            cabang: [],
            kategori: [],
            supplier: [],
            urlImage: null,
            preview: null,
            // tmbhModal: null,
            tambahSuccess: false,
            tambahError: false,
            tambaheditSuccess: false,
            tambaheditError: false,
            tambahdeleteSuccess: false,
            tambahdeleteError: false,
            tambahMessage: [],
            editMessage: [],
            updtmssg: [],

            idEdit: '',
            kantorcabangidEdit: '',
            kategoriidEdit: null,
            supplieridEdit: '',
            namaEdit: '',
            deskripsiEdit: '',
            barcodeEdit: '',
            hargacashEdit: '',
            hargahutangEdit: '',
            stokEdit: '',
            merekEdit: '',
            satuanEdit: '',
            jenisEdit: '',
            rakEdit: null,
            diskonEdit: 0,
            fileEdit: undefined,
            previewEdit: '',
            nowfoto: '',
            edited: false,
            loadfile: false,

            showdetailtipe: false,
            showdetailtipeedit: false,

            showdetail: false,
            tipeharga: {
                'nama_tipe': null,
                'min_jumlah': null,
                'harga': 0,
            },
            rowharga: [],
            tipehargaEdit: {
                'nama_tipe': null,
                'min_jumlah': null,
                'harga': 0,
            },
            rowhargaEdit: [],
            laravelData: {},
        }
    },

    created() {
        if (!this.loggedIn) {
            if (!this.token) {
                localStorage.removeItem("loggedIn");
                localStorage.removeItem("token");
                return this.$router.push({
                    name: 'login.index'
                })
            } else {
                localStorage.removeItem("loggedIn");
                localStorage.removeItem("token");
            }
        } else {
            this.getResults();
           
            this.getKategori();
            // this.getSupplier();
        }

    },

    methods: {
        handleFileUpload(event) {
            this.file = event.target.files[0];
            var input = event.target;
            console.log(input.files);
            if (input.files) {
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.preview = e.target.result;
                }
                reader.readAsDataURL(input.files[0]);
            }
        },
        handleExcelUpload(event) {
            this.importfile = event.target.files[0];

        },
        importfilestore() {
            var form = new FormData();
            this.loadfile = true;
            if (this.importfile) {
                form.append('filebarang', this.importfile);
            }
            form.append('cabang', this.searchCabang);
            axios.post(this.link + 'api/barang-import', form, {
                headers: {
                    'Authorization': this.token
                }
            }).then(res => {
                // this.tambaheditSuccess = true;
                this.$toast.show(`Data Berhasil Diupload`, {
                    position: "top",
                    duration: 1000,
                    max: 1,
                });
                this.loadfile = false;
                this.getResults();
            }).catch(error => {
                // if(){}
                // this.tambaheditError = true;
                this.$toast.show(`Data Gagal Diupload`, {
                    type: 'error',
                    position: "top",
                    duration: 1000,
                    max: 1,
                });
                this.loadfile = false;
                // // if()
                // this.editMessage = error.response.data.data.error;
                console.log(this.editMessage);
            })
        },
        handleFileUploadEdit(event) {
            this.fileEdit = event.target.files[0];
            var input = event.target;
            console.log(input.files);
            if (input.files) {
                var reader = new FileReader();
                reader.onload = (e) => {
                    this.previewEdit = e.target.result;
                }
                reader.readAsDataURL(input.files[0]);
            }
        },
        setClose() {
            this.edited = false;
            this.fileEdit = undefined;
            this.previewEdit = '';
        },
      
        getResults() {

            this.loading = true;
            console.log(this.loading);
            axios.get(this.link + 'api/barang?limit=10&name=' + this.search + '&page=' + this.page + '&cabang=' + this.searchCabang)
                .then(response => {

                    this.posts = response.data.data.data;
                    this.pagination = {
                        "total": response.data.data.total,
                        "per_page": response.data.data.per_page,
                        "current_page": response.data.data.current_page,
                        "last_page": response.data.data.last_page,
                        "next_page_url": response.data.data.next_page_url,
                        "prev_page_url": response.data.data.prev_page_url,
                        "from": response.data.data.from,
                        "to": response.data.data.to,
                    }
                }).then(
                    this.loading = false
                );
            console.log(this.loading);
        },
          getKategori() {
            axios.get(this.link + 'api/kategori', {
                headers: {
                    'Authorization': this.token
                }
            }).then(response => {
                console.log(response)
                this.kategori = response.data.data // assign response to state user
            })
        },
        getSupplier() {
            axios.get(this.link + 'api/supplier-semua', {
                headers: {
                    'Authorization': this.token
                }
            }).then(response => {
                console.log(response)
                this.supplier = response.data.data // assign response to state user
            })
        },
        getReset() {
            if (this.search != '') {
                this.search = "";
                this.page = 1;
                this.tambahdeleteError = false;
                this.tambahdeleteSuccess = false;
                this.getResults();
            }
        },
        onClick(item) {
            this.tambahdeleteError = false;
            this.tambahdeleteSuccess = false;
            this.page = item;
            this.getResults();
        },
        getSearch() {
            this.tambahdeleteError = false;
            this.tambahdeleteSuccess = false;
            this.page = 1;
            this.getResults();
        },
        onClicks(item) {
            this.tambahdeleteError = false;
            this.tambahdeleteSuccess = false;
            this.loading = true;

            axios.get(item + '&limit=10&name=' + this.search + '&cabang=' + this.searchCabang)
                .then(response => {
                    this.page = response.data.data.current_page;
                    this.posts = response.data.data.data;
                    this.pagination = {
                        "total": response.data.data.total,
                        "per_page": response.data.data.per_page,
                        "current_page": response.data.data.current_page,
                        "last_page": response.data.data.last_page,
                        "next_page_url": response.data.data.next_page_url,
                        "prev_page_url": response.data.data.prev_page_url,
                        "from": response.data.data.from,
                        "to": response.data.data.to,
                    }
                });
            this.loading = false;
        },
        showdetails() {
            this.showdetail = this.showdetail ? false : true;
        },
        simpantipeharga() {
            this.rowharga.push(this.tipeharga);
            this.tipeharga = {
                'nama_tipe': '',
                'min_jumlah': null,
                'harga': 0,
            }
            console.log(this.rowharga);
        },
        deleteindextipeharga(a) {
            this.rowharga.splice(a, 1);
        },
        showdetailtipes() {
            this.showdetailtipe = this.showdetailtipe ? false : true;
        },
        showdetailtipeeditsw() {
            this.showdetailtipeedit = this.showdetailtipeedit ? false : true;
        },
        deleteindextipehargaedit(a) {
            this.rowhargaEdit.splice(a, 1);
        },
        simpantipehargaedit() {
            this.rowhargaEdit.push(this.tipehargaEdit);
            this.tipehargaEdit = {
                'nama_tipe': '',
                'min_jumlah': null,
                'harga': 0,
            }
            console.log(this.rowharga);
        },
    }

}
</script>
